import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import { LinearProgress } from '../Progress';

import './index.scss';

@BEM('input-text-component')
export default class InputText extends React.PureComponent {
  static displayName = 'InputText';

  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      onChange: PropTypes.func,
    }),
    render: PropTypes.func,
    children: PropTypes.any,
    tag: PropTypes.any,
    type: PropTypes.string,
    help: PropTypes.any,
    label: PropTypes.string,
    loading: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    validateOnBlur: PropTypes.bool,
  };

  static defaultProps = {
    tag: 'input',
    type: 'text',
    loading: false,
    readOnly: false,
    disabled: false,
    validateOnBlur: true,
    meta: {},
    input: {},
  };

  render() {
    const { input, meta, children, render, label, help, disabled, loading, tag: Input, bem, validateOnBlur, ...other } = this.props;
    const error = meta.touched && (meta.error || (!meta.dirtySinceLastSubmit && meta.submitError));
    const className = bem({
      active: meta.active,
      fill: Boolean(input.value),
      error: Boolean(error),
      readonly: other.readOnly,
      loading,
      disabled,
    });

    return (
      <label className={className}>
        <Input className={bem('input')} {...other} {...input} />
        <span className={bem('label')}>{label}</span>
        <div className={bem('line')} />
        {error && <div className={bem('error')}>{error}</div>}
        {!error && help && <div className={bem('help')}>{help}</div>}
        {loading && (
          <div className={bem('loader')}>
            <LinearProgress />
          </div>
        )}
      </label>
    );
  }
}
