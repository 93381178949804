import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('chart-component')
export default class Chart extends React.PureComponent {
  static displayName = 'Chart';

  static propTypes = {
    labels: PropTypes.arrayOf(
      PropTypes.string,
    ),
    data: PropTypes.arrayOf(
      PropTypes.any,
    ),
    height: PropTypes.number,
  };

  static defaultProps = {
    labels: [],
    data: [],
    height: '100%'
  };

  backgroundFill = undefined;

  componentDidMount() {
    const $canvas = document.createElement('canvas');
    const context = $canvas.getContext('2d');
    const gradientFill = context.createLinearGradient(0, 0, 0, 400);

    gradientFill.addColorStop(0, 'rgba(64, 158, 237, 1)');
    gradientFill.addColorStop(1, 'rgba(64, 158, 237, 0)');

    this.backgroundFill = gradientFill;
    this.forceUpdate();
  }

  render() {
    const { height, data, labels, bem } = this.props;

    return (
      <div className={bem()}>
        <Line
          height={height}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              displayColors: false,
              yAlign: 'bottom',
              xAlign: 'center',
              // callbacks: {
              //   label: (item) => `${item.value} ${t('клієнтів')}`,
              // }
            },
            layout: {
              padding: {
                left: -4,
              }
            },
            scales: {
              xAxes: [{
                ticks: {
                  fontColor: '#819099',
                }
              }],
              yAxes: [{
                ticks: {
                  suggestedMax: (Math.max(...data) || 10) * 1.5,
                  suggestedMin: 0,
                  padding: 4,
                  fontColor: '#819099',
                }
              }]
            }
          }}
          data={{
            labels: labels,
            datasets: [
              {
                fill: true,
                borderColor: '#409EED',
                pointRadius: 4,
                pointBorderColor: '#409EED',
                pointBorderWidth: 4,
                pointBackgroundColor: '#fff',
                backgroundColor: this.backgroundFill,
                data: data,
              }
            ]
          }}
        />
      </div>
    );
  }
}
