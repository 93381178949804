import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BEM } from '@bikejs/react-bem';

import OuterClick from '../OuterClick';
import Icon from '../Icon';

import { selectModal, hideModal } from './redux';

import './index.scss';

@connect((store, { name }) => ({
  modal: selectModal(store)(name),
}), { hideModal })
@BEM('modal-component')
export default class Modal extends React.PureComponent {
  static displayName = 'Modal';

  static propTypes = {
    name: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    autoWidth: PropTypes.bool,
  };

  static defaultProps = {
    onClose: () => {},
    autoWidth: false,
  };

  onClose() {
    this.props.hideModal(this.props.name);
    this.props.onClose(this.props.modal);
  }

  render() {
    const { modal, autoWidth, children, bem } = this.props;

    if (!modal || !modal.show) {
      return null;
    }

    return ReactDOM.createPortal((
      <div className={bem({'auto-width': autoWidth})}>
        <OuterClick onClick={() => this.onClose()}>
          <section className={bem('dialog')}>
            <button className={bem('close')} type="button" onClick={() => this.onClose()}>
              <Icon name="close" />
            </button>
            {React.cloneElement(children, {modal: {...modal, close: () => this.onClose()}})}
          </section>
        </OuterClick>
      </div>
    ), document.body);
  }
}
