import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { connect } from 'react-redux';

import Box from 'components/Box';
import Title from 'components/Title';

import { selectUserData } from 'common/redux/energy/selectors';

import './index.scss';

@connect((state) => ({
  user: selectUserData(state),
}))
@withTranslation()
@BEM('company-name-energy-block')
export class CompanyNameEnergyBlock extends React.PureComponent {
  static displayName = 'CompanyNameEnergyBlock';

  render() {
    const { user, bem } = this.props;

    return (
      <Box>
        <section className={bem()}>
          <header className={bem('header')}>
            <Title size="h3">{user.name}</Title>
            <img className={bem('fire')} src="/assets/images/power-image.svg" />
          </header>
        </section>
      </Box>
    );
  }
}
