import { createSelector } from 'reselect';

import { getShortName } from 'common/helpers/string.helper';

import { NAMESPACE } from 'common/redux/cabinet';

export const cabinet = (state) => state[NAMESPACE];
export const lang = (state) => state.i18n.lang;

export const selectCompaniesList = createSelector(
  cabinet,
  ({ companies }) => companies,
);

export const selectHeaderInfo = createSelector(
  cabinet,
  lang,
  ({ user }, lang) => ({
    ...user,
    name: getShortName(user.name),
    tariff: {
      ...user.tariff,
      name: user.tariff.name[lang],
    },
  }),
);

export const selectUserInfo = createSelector(cabinet, ({ user }) => user);

export const selectBillInfo = createSelector(
  cabinet,
  ({ user }) => user.bill,
)

export const selectBalance = createSelector(
  cabinet,
  ({ balance }) => balance,
);

export const selectPriceChart = createSelector(
  cabinet,
  ({ priceChart }) => priceChart,
);

export const selectBills = createSelector(
  cabinet,
  ({ bills }) => bills,
);

export const selectAgreements = createSelector(
  cabinet,
  ({ agreements }) => agreements,
);

export const selectActs = createSelector(
  cabinet,
  ({ acts }) => acts,
);
