const LISTENERS = new WeakMap();

export class Handler {
	trigger(event) {
		if (!LISTENERS.has(this)) {
			return;
		}

		LISTENERS.get(this).forEach(({ callback }) => callback(event))
	}

	onChange(callback) {
		if (!LISTENERS.has(this)) {
			LISTENERS.set(this, []);
		}

		LISTENERS.get(this).push({ callback });
	}

	[Symbol.toStringTag]() {
		return 'Handler';
	}
}
