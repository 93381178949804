import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useBEM } from '@bikejs/react-bem';

import Loader from 'components/Loader';

import './index.scss';

export const AuthorizationLayout = ({ children, image }) => {
  const bem = useBEM('authorization-layout');
  const { t, i18n } = useTranslation();

  return (
    <section className={bem()}>
      <article className={bem('main')}>
        <div className={bem('left')}>
          <div className={bem('content')}>
            <Link to="/">
              <img className={bem('logo')} src="/assets/images/logo-white.svg" />
            </Link>
            <div className={bem('text')}>
              Більше не доведеться чекати поки
              бажання здійсниться - низька ціна,
              вигідні умови  та зручна система
              оплати вже твої.

              <div className={bem('text-footer')}>
                Економ разом із TAS Energy!
              </div>
            </div>
          </div>
          <div className={bem('bg')}>
            <img className={bem('image')} src="/assets/images/authorization-bg.png"/>
            <div style={{ backgroundImage: `url(${image})` }} />
          </div>
        </div>
        <div className={bem('right')}>
          <Link to="/" hidden className={bem('mobile')}>
            <img src="/assets/images/logo.svg" />
          </Link>
          <header className={bem('right-header')}>
            {/*<div className={bem('lang')}>*/}
            {/*  <DropDown*/}
            {/*    active={i18n.language}*/}
            {/*    options={[*/}
            {/*      {*/}
            {/*        label: t('Українська'),*/}
            {/*        value: 'uk',*/}
            {/*      }, {*/}
            {/*        label: t('Русский'),*/}
            {/*        value: 'ru',*/}
            {/*      }*/}
            {/*    ]}*/}
            {/*    onChange={({ value }) => i18n.changeLanguage(value)}*/}
            {/*  />*/}
            {/*</div>*/}
          </header>
          <article className={bem('right-main')}>
            <div className={bem('form')}>
              {children}
              <Loader name="auth" color="white" />
            </div>
          </article>
        </div>
      </article>
    </section>
  );
};
