const SHOW_LOADER = '@loader/SHOW';
const HIDE_LOADER = '@loader/HIDE';

const store = { dispatch: () => { }, getState: () => ({ }) };

export const showLoader = (name = 'global') => ({
  type: SHOW_LOADER,
  payload: { name },
});

export const hideLoader = (name = 'global') => ({
  type: HIDE_LOADER,
  payload: { name },
});

export const loaderMiddleware = ({ dispatch, getState }) => {
  store.dispatch = dispatch;
  store.getState = getState;

  return next => action => next(action);
};

export const connectLoader = () => (state = {}, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        [action.payload.name]: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        [action.payload.name]: false,
      };
    default:
      return state;
  }
};

export const withLoader = (name, func, context) => (...args) => {
  store.dispatch(showLoader(name));

  return func.apply(context, args).then((result) => {
    store.dispatch(hideLoader(name));
    return result;
  }).catch((error) => {
    store.dispatch(hideLoader(name));
    return Promise.reject(error);
  });
};
