import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { connect } from 'react-redux';

import Icon from 'components/Icon';
import Box from 'components/Box';

import { selectUserData } from 'common/redux/energy/selectors';

import './index.scss';

@connect((state) => ({
  user: selectUserData(state),
}))
@withTranslation()
@BEM('user-data-block')
export class UserDataEnergyBlock extends React.PureComponent {
  static displayName = 'UserDataEnergyBlock';

  render() {
    const { user, t, bem } = this.props;

    return (
      <Box>
        <section className={bem()}>
          <header className={bem('header')}>
            <div className={bem('col')}>
              <div className={bem('data')}>
                <p className={bem('label')}>{t('Юр. адреса')}</p>
                <p className={bem('value')}>{user.addressLegal}</p>
              </div>
              <div className={bem('data')}>
                <p className={bem('label')}>{t('Код ЄДРПОУ')}</p>
                <p className={bem('value')}>{user.code}</p>
              </div>
            </div>
            <div className={bem('col')}>
              <div className={bem('data')}>
                <p className={bem('label')}>{t('Користувач')}</p>
                <p className={bem('value')}>{user.userDetails.name}</p>
              </div>
              <p className={bem('icon-row')}>
                <span className={bem('icon-row-icon')}>
                  <Icon color="#666" name="call" size={16} />
                </span>
                {user.userDetails.phone}
                <span className={bem('icon-row-icon', { next: true })}>
                  <Icon color="#666" name="email" size={16} />
                </span>
                {user.userDetails.email}
              </p>
              <p className={bem('icon-row')}>
                <span className={bem('icon-row-icon')}>
                  <Icon color="#666" name="work" size={16} />
                </span>
                {user.userDetails.post_title}
              </p>
            </div>
          </header>
          <hr className={bem('line')} />
          {user.properties.map((item) => (
            <div className={bem('row')} key={item.eic_code}>
              <img className={bem('fire')} src="/assets/images/power.svg" />
              <div className={bem('data', { row: true })}>
                <p className={bem('label')}>{t('EIC-код')}</p>
                <p className={bem('value')}>
                  {item.eic_code}
                </p>
              </div>
              <div className={bem('data', { row: true })}>
                <p className={bem('label')}>{t('Оператор системи розподілу (ОСП)')}</p>
                <p className={bem('value')}>{item.osr}</p>
              </div>
              <div className={bem('data', { row: true })}>
                <p className={bem('label')}>{t('Група')}</p>
                <p className={bem('value')}>{item.group}</p>
              </div>
              <div className={bem('data', { row: true })}>
                <p className={bem('label')}>{t('Клас')}</p>
                <p className={bem('value')}>{item.class}</p>
              </div>
            </div>
          ))}
          <hr className={bem('line')} />
          <div className={bem('data')}>
            <p className={bem('label')}>{t('Тип документообігу')}</p>
            <p className={bem('value')}>
              <span className={bem('icon-row-icon')}>
                <Icon color="#666" name="email" size={16} />
              </span>
              {user.documentFlow || '--'}
            </p>
          </div>
          <div className={bem('data')}>
            <p className={bem('label')}>{t('Поштова адреса')}</p>
            <p className={bem('value')}>{user.addressPost || '--'}</p>
          </div>
        </section>
      </Box>
    );
  }
}
