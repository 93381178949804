import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import OuterClick from '../OuterClick';
import Icon from '../Icon';

import './index.scss';

const DEFAULT_MARGIN = {
  top: 8,
  left: 0,
  right: 0,
};

@BEM('drop-down-component')
export default class DropDown extends React.PureComponent {
  static displayName = 'DropDown';

  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.string,
      })
    ),
    onChange: PropTypes.func,
    margin: PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    align: PropTypes.oneOf(['left', 'right']),
    valign: PropTypes.oneOf(['top', 'bottom']),
    nowrap: PropTypes.bool,
    arrow: PropTypes.bool,
    controlRender: PropTypes.any,
    label: PropTypes.any,
    width: PropTypes.any,
  };

  static defaultProps = {
    align: 'left',
    valign: 'bottom',
    options: [],
    onChange: () => {},
    nowrap: false,
    arrow: true,
    margin: DEFAULT_MARGIN,
    controlRender: (props) => props.active ? props.active.label : props.label,
    width: 'initial',
  };

  static getDerivedStateFromProps(nextProps, state) {
    const active = nextProps.options.find(({ value }) => value === nextProps.active);

    return {
      active: active || state.active,
    };
  }

  state = {
    open: false,
    active: !this.props.label ? this.props.options[0] : undefined,
  };

  onSelect(e) {
    const active = this.props.options.find(({ value }) => value === e.target.closest('li').dataset.value);

    this.props.onChange(active);

    this.setState({ open: false, active });
  }

  onToggle(open = !this.state.open) {
    this.setState({ open });
  }

  render() {
    const { bem, options, nowrap, arrow, align, valign, label, width, controlRender: Control } = this.props;
    const { open, active } = this.state;
    const margin = Object.assign({}, DEFAULT_MARGIN, this.props.margin);

    return (
      <OuterClick onClick={() => this.onToggle(false)}>
        <div className={bem({ open, nowrap, arrow, align, valign })}>
          <div
            className={bem('label')}
            onClick={(e) => {
              e.stopPropagation();
              this.onToggle();
            }}
          >
            <Control active={active} label={label} />
            {arrow && (
              <div className={bem('arrow')}>
                <Icon name="expand_more" size={18} />
              </div>
            )}
          </div>
          <ul
            className={bem('options')}
            style={{ margin: `${margin.top}px ${margin.right}px 0 ${margin.left}px`, width }}
            onClick={(e) => this.onSelect(e)}
          >
            {options.map(item => (
              <li className={bem('option')} key={item.value} data-value={item.value}>
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </OuterClick>
    );
  }
}
