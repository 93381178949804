import { matchPath } from 'react-router';

const COMPANY_ID_PATH_PATTERN = '/cabinet/:companyId';

export const matchCompanyId = (pathname) => {
  const match = matchPath(pathname, {
    path: COMPANY_ID_PATH_PATTERN,
  });

  if (!match || !match.params) {
    return undefined;
  }

  return match.params.companyId;
}

export const matchType = (pathname) => {
  const match = matchPath(pathname, {
    path: '/cabinet/:companyId/:type'
  });

  if (!match || !match.params) {
    return 'gas';
  }

  return match.params.type;
}
