import React from 'react';
import PropTypes from 'prop-types';
import Mask from 'react-input-mask';

import InputText from '../InputText';

export default class InputMask extends React.PureComponent {
  static displayName = 'InputMask';

  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      onChange: PropTypes.func,
    }),
    render: PropTypes.func,
    children: PropTypes.any,
    tag: PropTypes.oneOf(['input', 'textarea']),
    type: PropTypes.string,
    help: PropTypes.any,
    label: PropTypes.string,
    mask: PropTypes.string,
    maskPlaceholder: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
  };

  static defaultProps = {
    tag: 'input',
    type: 'text',
    meta: {},
    input: {},
    maskPlaceholder: ' ',
  };

  render() {
    const { input, mask, alwaysShowMask, maskPlaceholder, ...props } = this.props;

    return (
      <Mask
        mask={mask}
        alwaysShowMask={alwaysShowMask}
        maskPlaceholder={maskPlaceholder}
        readOnly={props.readOnly}
        {...input}
      >
        <InputText {...props} input={{ value: input.value }} />
      </Mask>
    );
  }
}
