import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('notifications-component')
export default class Notifications extends React.PureComponent {
  static displayName = 'Notifications';

  static propTypes = {
    notifications: PropTypes.array,
    onHide: PropTypes.func,
  };

  static defaultProps = {
    notifications: [],
    onHide: () => {},
  };

  animations = new Set();
  type = 'success';

  onAnimationEnd(e, notification) {
    if (!this.animations.has(notification.key)) {
      this.animations.add(notification.key);
      return ;
    }

    this.props.onHide(notification);
  }

  render() {
    const { notifications, bem } = this.props;
    this.type = notifications.length ? notifications[notifications.length - 1].type : this.type;

    return (
      <div className={bem({ show: Boolean(notifications.length) })}>
        <ul className={bem('notifications')}>
          {notifications.map((item) => (
            <li
              className={bem('notification', { type: item.type })}
              onAnimationEnd={(e) => this.onAnimationEnd(e, item)}
              key={item.key}
            >
              <h3 className={bem('title')}>
                {item.title}
              </h3>
              <div className={bem('description')}>
                {item.message}
              </div>
            </li>
          ))}
        </ul>

        <div className={bem('gas', { type: this.type })} />
      </div>
    );
  }
}
