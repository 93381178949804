import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { BEM } from '@bikejs/react-bem';

import Title from 'components/Title';
import Period from 'components/Period';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { withLoader } from 'components/Loader/redux';

import { Cabinet } from 'common/redux/cabinet';
import { EnergyService } from 'common/redux/energy';

import './index.scss';

@withTranslation()
@BEM('service-act-form')
export class ServiceActForm extends React.PureComponent {
  static displayName = 'ServiceActForm';

  static propTypes = {
    type: PropTypes.oneOf(['gas', 'energy']),
  }

  async onSubmit({ period }) {
    const { type, modal } = this.props;

    await withLoader('service-act', type === 'gas' ? Cabinet.getBalanceAct : EnergyService.getBalanceAct, type === 'gas' ? Cabinet : EnergyService)({
      query: {
        from: moment(period.from).format('DD-MM-YYYY'),
        to: moment(period.to).format('DD-MM-YYYY'),
      },
    });

    modal.close();
  }

  render() {
    const { t, bem } = this.props;

    return (
      <Form
        initialValues={{
          period: {
            from: new Date().setMonth(new Date().getMonth() - 1),
            to: new Date(),
          }
        }}
        onSubmit={(values) => this.onSubmit(values)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <header className={bem('header')}>
              <Title size="h3">{t('Згенерувати акт звірки')}</Title>
            </header>
            <article className={bem('main')}>
              <div className={bem('label')}>{t('Період')}</div>
              <Field
                name="period"
                render={({input}) => (
                  <Period
                    period={input.value}
                    onChange={(value) => input.onChange(value)}
                  />
                )}
              />
            </article>
            <footer className={bem('footer')}>
              <Button type="submit">
                {t('Отримати')}
              </Button>
            </footer>
            <Loader name="service-act" size={32} />
          </form>
        )}
      />
    );
  }
}
