import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('progress-linear-component')
export class LinearProgress extends React.PureComponent {
  static displayName = 'LinearProgress';

  static propTypes = {
    color: PropTypes.string,
    bg: PropTypes.string,
    size: PropTypes.number,
  };

  static defaultProps = {
    color: '#3f51b5',
    bg: '#f5f5f5',
    size: 1,
  };

  render() {
    const { size, bg, color, bem } = this.props;

    return (
      <div style={{ height: size, backgroundColor: bg }} className={bem()}>
        <div style={{ backgroundColor: color }} className={bem('line')} />
      </div>
    );
  }
}

@BEM('progress-circle-component')
export class CircleProgress extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    stroke: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  static defaultProps = {
    color: '#3f51b5',
    size: 64,
    stroke: 2
  };

  render() {
    const { color, size, stroke, bem } = this.props;

    return (
      <div style={{ width: size, height: size }} className={bem()}>
        <svg className={bem('main')} viewBox="25 25 50 50">
          <circle
            className={bem('circle')}
            cx="50"
            cy="50"
            r={20}
            fill="none"
            stroke={color}
            strokeWidth={stroke}
            strokeMiterlimit="10"
            strokeDasharray="1, 200"
            strokeDashoffset="0"
          />
        </svg>
      </div>
    )
  }
}
