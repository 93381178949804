import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('input-radio-component')
export default class InputRadio extends React.PureComponent {
  static displayName = 'InputRadio';

  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      onChange: PropTypes.func,
    }),
    render: PropTypes.func,
    children: PropTypes.any,
    tag: PropTypes.oneOf(['input', 'textarea']),
    type: PropTypes.string,
    help: PropTypes.any,
    label: PropTypes.any,
  };

  static defaultProps = {
    meta: {},
    input: {
      type: 'checkbox'
    }
  };

  render() {
    const { input, meta, children, render, label, help, disabled, bem, ...other } = this.props;
    const error = (meta.touched || meta.submitFailed) && meta.error;
    const className = bem({ error: Boolean(error), disabled, checked: input.checked });

    return (
      <label className={className}>
        <input type="radio" className={bem('input')} {...other} {...input} />
        <div className={bem('border', { checked: input.checked })}>
          <div className={bem('dot', { checked: input.checked })} />
        </div>
        <span className={bem('label')}>{label}</span>
        {error && <div className={bem('error')}>{error}</div>}
      </label>
    );
  }
}
