import { replace } from 'connected-react-router'

import { Endpoint, HttpMethod } from 'common/plugins/colibri';

import CONFIG from 'common/config';

import { BaseAPI } from 'common/redux';
import i18n from 'common/services/i18n.service';
import { sessionStorage } from 'common/services/storage.service';

import { Cabinet } from 'common/redux/cabinet';

import {errorsToObject} from 'common/helpers/errors.helper';

class AuthAPI extends BaseAPI {
  @Endpoint({
    path: '/login_check',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async login(response) {
    if (response.status === 401) {
      return {
        get username() {
          return i18n.t('Невірний логін або пароль');
        }
      }
    }

    if (!response.ok) {
      return ;
    }

    if (response.body.user_roles && response.body.user_roles.includes('ROLE_MANAGER')) {
      window.location.href = CONFIG.SELLER_DASHBOARD_URL;
      return;
    }

    await Cabinet.getCompanies();

    return {
      next: {
        path: `/cabinet/${Cabinet.getCompanyId()}/gas`,
      },
    };
  }

  @Endpoint({
    path: '/logout',
    method: HttpMethod.GET,
  })
  async logout() {
    this.store.dispatch(replace('/login'));
  }

  @Endpoint({
    path: '/public/registration/step_first',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async registration(response, request) {
    sessionStorage.set('registration.first', request.body);

    if (response.status === 400) {
      return errorsToObject(response.body);
    }

    if (!response.ok) {
      return;
    }

    sessionStorage.set('registration.first-response', response.body);

    return {
      next: {
        path: '/registration/phones',
      },
    };
  }

  @Endpoint({
    path: '/public/registration/step_second',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async registrationStepPhones(response, request) {
    if (!response.ok) {
      return;
    }

    sessionStorage.set('registration.phone', request.body);

    return {
      next: {
        path: '/registration/otp',
      },
    };
  }

  @Endpoint({
    path: '/public/registration/step_final',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async registrationStepOTP(response) {
    if (response.status === 400) {
      return {
        get code() {
          return i18n.t('Невірний код OTP');
        }
      }
    }

    if (!response.ok) {
      return ;
    }

    sessionStorage.remove('registration');

    return {
      next: {
        path: '/registration/finish',
      },
    };
  }

  @Endpoint({
    path: '/public/recovery/step_first',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async recoveryPassword(response, request) {
    sessionStorage.set('recovery.first', request.body);

    if (response.status === 400) {
      return errorsToObject(response.body);
    }

    if (!response.ok) {
      return;
    }

    sessionStorage.set('recovery.first-response', response.body);

    return {
      next: {
        path: '/recovery/step/two',
      },
    };
  }

  @Endpoint({
    path: '/public/recovery/step_second',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async recoverySendOTP(response, request) {
    if (response.status === 400) {
      return errorsToObject(response.body);
    }

    if (!response.ok) {
      return;
    }

    sessionStorage.set('recovery.send', request.body);

    return {
      next: {
        path: '/recovery/step/three',
      },
    };
  }

  @Endpoint({
    path: '/public/recovery/step_final',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async recoveryConfirmOTP(response) {
    if (response.status === 400) {
      return {
        get code() {
          return i18n.t('Невірний код OTP');
        },
      };
    }

    if (!response.ok) {
      return ;
    }

    sessionStorage.remove('recovery');

    return {
      next: {
        path: '/recovery/step/finish',
      },
    };
  }
}

export const Auth = new AuthAPI();
