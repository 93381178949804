import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

const TITLE_TYPES = ['h1', 'h2', 'h3', 'h4', 'h5', 'h5', 'h6'];

@BEM('title')
export default class Title extends React.PureComponent {
  static displayName = 'Title';

  static propTypes = {
    size: PropTypes.oneOf(TITLE_TYPES),
    tag: PropTypes.oneOf([
      ...TITLE_TYPES,
      'div',
      'section'
    ]),
    children: PropTypes.any.isRequired,
    theme: PropTypes.oneOf(['default']),
  };

  static defaultProps = {
    theme: 'default',
    tag: 'h1',
  };

  render() {
    const { children, theme, size, tag, bem } = this.props;

    return React.createElement(tag, {
      className: bem({ theme, size: size || tag }),
      children,
    })
  }
}

export const H1 = props => React.createElement(Title, { ...props, tag: 'h1' });
export const H2 = props => React.createElement(Title, { ...props, tag: 'h2' });
export const H3 = props => React.createElement(Title, { ...props, tag: 'h3' });
export const H4 = props => React.createElement(Title, { ...props, tag: 'h4' });
export const H5 = props => React.createElement(Title, { ...props, tag: 'h5' });
export const H6 = props => React.createElement(Title, { ...props, tag: 'h6' });
