import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { CompanyNameBlock } from 'common/blocks/CompanyName';
import { UserDataBlock } from 'common/blocks/UserData';
import { PriceChartBlock } from 'common/blocks/PriceChart';

import './index.scss';

@withTranslation()
@BEM('main-page')
export class MainPage extends React.PureComponent {
  static displayName = 'MainPage';

  render() {
    const { bem } = this.props;

    return (
      <section className={bem()}>
        <div className={bem('row')}>
          <CompanyNameBlock />
        </div>
        <div className={bem('row')}>
          <UserDataBlock />
        </div>
        <div className={bem('row')}>
          <PriceChartBlock />
        </div>
      </section>
    );
  }
}
