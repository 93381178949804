import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { connect } from 'react-redux';

import Box from 'components/Box';
import Title from 'components/Title';

import { selectUserInfo } from 'common/redux/cabinet/selectors';

import './index.scss';

@connect((state) => ({
  user: selectUserInfo(state),
}))
@withTranslation()
@BEM('company-name-block')
export class CompanyNameBlock extends React.PureComponent {
  static displayName = 'CompanyNameBlock';

  render() {
    const { user, bem } = this.props;

    return (
      <Box>
        <section className={bem()}>
          <header className={bem('header')}>
            <Title size="h3">{user.name}</Title>
            <img className={bem('fire')} src="/assets/images/fire-image.png" />
          </header>
        </section>
      </Box>
    );
  }
}
