import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { fetch } from 'common/services/fetch.service';
import { Cabinet } from 'common/redux/cabinet';
import { selectPriceChart } from 'common/redux/cabinet/selectors';

import Title from 'components/Title';
import Box from 'components/Box';
import Chart from 'components/Chart';
import Loader from 'components/Loader';

import { numberFormatter } from 'common/helpers/string.helper';
import { MONTHS } from 'common/helpers/months.helper';

import './index.scss';

@fetch({
  data: [selectPriceChart, () => Cabinet.getPriceChart()],
}, null, {loader: 'chart'})
@withTranslation()
@BEM('price-chart-block')
export class PriceChartBlock extends React.PureComponent {
  static displayName = 'PriceChartBlock';

  render() {
    const { data, t, bem } = this.props;

    return (
      <Box>
        <section className={bem()}>
          <div className={bem('left')}>
            <div className={bem('title')}>
              <Title size="h3">{t('Динаміка зміни ціни на природний газ')}</Title>
            </div>
            <div className={bem('chart')}>
              <Chart
                height={400}
                labels={data.map(({month}) => String(month))}
                data={data.map(({price}) => price)}
              />
            </div>
          </div>
          <ul className={bem('right')}>
            {data.slice(0, 5).reverse().map(({month, price}) => (
              <li className={bem('month-item')} key={month}>
                <div className={bem('month-label')}>
                  {MONTHS[month]}
                </div>
                <div className={bem('month-value')}>
                  {numberFormatter(price)} ₴
                </div>
              </li>
            ))}
          </ul>
        </section>
        <Loader name="chart" color="white" />
      </Box>
    );
  }
}
