import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import Icon from '../Icon';

import './index.scss';

@BEM('table-component')
export default class Table extends React.PureComponent {
  static displayName = 'Table';

  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.any,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      align: PropTypes.oneOf(['left', 'center', 'right'])
    })),
    rows: PropTypes.arrayOf(PropTypes.object),
    theme: PropTypes.oneOf(['default', 'plain', 'inset']),
    size: PropTypes.oneOf(['small', 'middle']),
    hover: PropTypes.bool,
    onClick: PropTypes.func,
    innerRender: PropTypes.any,
    innerOnClick: PropTypes.bool,
    scroll: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    headerComponent: PropTypes.any,
    responsiveWidth: PropTypes.number,
    placeholder: PropTypes.any,
    pagination: PropTypes.shape({
      page: PropTypes.number,
      pages: PropTypes.number,
      limit: PropTypes.number,
      total: PropTypes.number,
      onChange: PropTypes.func,
    }),
    minWidth: PropTypes.any,
  };

  static defaultProps = {
    theme: 'default',
    size: 'middle',
    columns: [],
    rows: [],
    hover: false,
    innerRender: undefined,
    innerOnClick: false,
    responsiveWidth: 0,
    placeholder: 'No content',
    onClick: undefined,
    minWidth: 'initial',
  };

  state = {
    open: [],
    responsive: false,
  };

  $table = createRef();

  componentDidMount() {
    const { responsiveWidth } = this.props;

    if (!responsiveWidth) {
      return ;
    }

    if (this.$table.current.clientWidth < responsiveWidth) {
      this.setState({ responsive: true });
    }
  };

  toggleInnerRow(e) {
    e.stopPropagation();

    const $tr = e.target.closest('tr');
    const index = Number($tr.dataset.index);
    const { open } = this.state;

    if (this.props.onClick) {
      this.props.onClick(this.props.rows[index], index);
    }

    if (index === undefined || this.props.innerRender === undefined) {
      return;
    }

    if (open.includes(index)) {
      open.splice(open.indexOf(index), 1);
    } else {
      open.push(index);
    }

    this.setState({ open: open.slice() });
  }

  render() {
    const {
      columns, rows, theme, size, hover, scroll, headerComponent: HeaderComponent,
      onClick, innerRender, innerOnClick, pagination, placeholder, minWidth, bem,
    } = this.props;

    const { open, responsive } = this.state;
    const tbodyStyles = Boolean(scroll) ? { maxHeight: scroll } : undefined;

    return (
      <table
        style={{minWidth}}
        className={bem({ theme, size, hover, pointer: innerOnClick, scroll: Boolean(scroll), responsive, header: Boolean(HeaderComponent) })}
        ref={this.$table}
      >
        <thead>
          {HeaderComponent && (
            <tr>
              <td colSpan={columns.length}>
                <HeaderComponent />
              </td>
            </tr>
          )}
          <tr>
            {columns.map(item => (
              <th align={item.align} className={bem('th', { align: item.align })} width={item.width} key={item.id}>
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={tbodyStyles} onClick={(e) => this.toggleInnerRow(e)}>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <tr className={bem('row', {pointer: Boolean(onClick)})} data-index={index}>
              {columns.map(column => (
                <td data-label={column.label} align={column.align} className={bem('column')} key={`${column.id}-${index}`}>
                  {row[column.id]}
                </td>
              ))}
            </tr>
            {innerRender && open.includes(index) && (
              <tr className={bem('row-inner')}>
                <td className={bem('column-inner')} colSpan={columns.length}>
                  {innerRender({ rows, columns, row })}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
        {rows.length === 0 && (
          <tr>
            <td colSpan={columns.length}>
              <div className={bem('placeholder')}>
                {placeholder}
              </div>
            </td>
          </tr>
        )}
        </tbody>
        {pagination && rows.length !== 0 && (
          <tfoot className={bem('footer')}>
            <tr className={bem('footer-row')}>
              <td className={bem('footer-column')} colSpan={columns.length}>
                <div className={bem('pagination')}>
                  <div className={bem('pagination-total')}>
                    {((pagination.page - 1) * pagination.limit) || 1} - {(pagination.page * pagination.limit) > pagination.total ? pagination.total : (pagination.page * pagination.limit)}
                    &nbsp;з&nbsp;
                    {pagination.total}
                  </div>
                  <div className={bem('pagination-controls')}>
                    <button
                      type="button"
                      onClick={() => pagination.onChange(pagination.page - 1, 'prev')}
                      className={bem('pagination-button', { prev: true, disabled: pagination.page === 1 })}
                    >
                      <Icon name="chevron_left" />
                    </button>
                    <button
                      type="button"
                      onClick={() => pagination.onChange(pagination.page + 1, 'next')}
                      className={bem('pagination-button', { next: true, disabled: (pagination.page * pagination.limit) >= pagination.total })}
                    >
                      <Icon name="chevron_right" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    );
  }
}
