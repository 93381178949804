import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputSelect from 'components/InputSelect';
import Button from 'components/Button';

import { MONTHS } from 'common/helpers/months.helper';

import './index.scss';

@withTranslation()
@BEM('accounts-filter-form')
export class AccountsFilterForm extends React.PureComponent {
  static displayName = 'AccountsFilterForm';

  render() {
    const { onSubmit, onReset, t, bem } = this.props;
    const currentDate = new Date();

    return (
      <Form
        initialValues={{
          month: String(currentDate.getMonth()),
          year: String(currentDate.getFullYear()),
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, initialValues }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('filters')}>
              <div className={bem('field')}>
                <Field
                  name="month"
                  component={InputSelect}
                  label={t('Місяць')}
                  options={Object.values(MONTHS).map((label, index) => ({
                    label,
                    value: String(index + 1),
                  }))}
                />
              </div>
              <div className={bem('field')}>
                <Field
                  name="year"
                  component={InputSelect}
                  label={t('Рік')}
                  options={Array.from(new Array(5), () => currentDate.getFullYear()).map((label, index) => ({
                    label: String(label - index),
                    value: String(label - index),
                  }))}
                />
              </div>
            </div>
            <div className={bem('controls')}>
              <div className={bem('control', {submit: true})}>
                <Button type="submit">
                  {t('Застосувати')}
                </Button>
              </div>
              <div className={bem('control')}>
                <Button
                  type="button"
                  theme="link"
                  onClick={() => {
                    form.reset();
                    onReset(initialValues);
                  }}
                >
                  {t('Скинути')}
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}
