import React from 'react';

import { LoginPage } from 'common/pages/Login';

import { RegistrationLayout } from 'common/layouts/Registration';
import { RegistrationForm } from 'common/forms/Registration';
import { RegistrationStepPhonesForm } from 'common/forms/RegistrationStepPhones';
import { RegistrationStepOTPForm } from 'common/forms/RegistrationStepOTP';
import { RegistrationFinishPage } from 'common/pages/RegistrationFinish';

import { RecoveryLayout } from 'common/layouts/Recovery';
import { RecoveryForm } from 'common/forms/Recovery';
import { RecoveryStepTwoForm } from 'common/forms/RecoveryStepTwo';
import { RecoveryStepThreeForm } from 'common/forms/RecoveryStepThree';
import { RecoveryFinishPage } from 'common/pages/RecoveryFinish';

export const authRoutes = [
  {
    path: '/login',
    exact: true,
    component: LoginPage,
  }, {
    path: '/registration/finish',
    exact: true,
    component: RegistrationFinishPage,
  }, {
    path: '/registration',
    component: RegistrationLayout,
    routes: [
      {
        path: '/registration',
        exact: true,
        component: RegistrationForm,
      }, {
        path: '/registration/phones',
        exact: true,
        component: RegistrationStepPhonesForm,
      }, {
        path: '/registration/otp',
        exact: true,
        component: RegistrationStepOTPForm,
      }
    ]
  }, {
    path: '/recovery/step/finish',
    exact: true,
    component: RecoveryFinishPage,
  }, {
    path: '/recovery',
    component: RecoveryLayout,
    routes: [
      {
        path: '/recovery',
        step: 1,
        exact: true,
        component: RecoveryForm,
      }, {
        path: '/recovery/step/two',
        step: 2,
        exact: true,
        component: RecoveryStepTwoForm,
      }, {
        path: '/recovery/step/three',
        step: 3,
        exact: true,
        component: RecoveryStepThreeForm,
      }
    ],
  },
];
