import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputText from 'components/InputText';
import InputPassword from 'components/InputPassword';
import Button from 'components/Button';
import { H6 } from 'components/Title';

import { sessionStorage } from 'common/services/storage.service';

import { withLoader } from 'components/Loader/redux';
import { Auth } from 'common/redux/auth';

import {
  required, validate, passwordMatch, password, email,
} from 'common/helpers/validators.helper';

import './index.scss';

@withTranslation()
@BEM('registration-form')
export class RegistrationForm extends React.PureComponent {
  static displayName = 'RegistrationForm';

  async onSubmit(values) {
    const data = await withLoader('auth', Auth.registration, Auth)({
      body: values,
    });

    if (data.next) {
      this.props.history.push(data.next.path);
    }

    return data;
  }

  render() {
    const { bem, t } = this.props;

    return (
      <Form
        onSubmit={values => this.onSubmit(values)}
        initialValues={sessionStorage.get('registration.first')}
        validate={validate(
          passwordMatch({
            password: 'password',
            repeat: 'passwordConfirm'
          })
        )}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('sub')}>
              <H6>{t('Будь ласка, внесіть Ваші персональні дані')}</H6>
            </div>
            <div className={bem('row')}>
              <Field
                name="email"
                component={InputText}
                autoComplete="off"
                label={t('Email')}
                validate={validate(
                  required(),
                  email(),
                )}
              />
            </div>
            <div className={bem('row')}>
              <Field
                name="password"
                component={InputPassword}
                label={t('Пароль')}
                validate={validate(
                  required(),
                  password(),
                )}
              />
            </div>
            <div className={bem('row')}>
              <Field
                name="passwordConfirm"
                component={InputPassword}
                label={t('Підтвердити пароль')}
                validate={validate(
                  required(),
                )}
              />
            </div>
            <footer className={bem('footer')}>
              <Button type="submit" disabled={submitting} block>
                {t('Далі')}
              </Button>

              <div className={bem('login')}>
                {t('Вже зареєстровані?')}&nbsp;
                <Button theme="link" to="/login" lower>
                  {t('Авторизуватися')}
                </Button>
              </div>
            </footer>
          </form>
        )}
      />
    );
  }
}
