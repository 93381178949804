import { replace } from 'connected-react-router'

import { API, Service, Endpoint, HttpMethod } from 'common/plugins/colibri';

import i18n from 'common/services/i18n.service';
import { showNotification } from 'components/Notifications/redux';
import { sessionStorage } from 'common/services/storage.service';

let sessionId = sessionStorage.get('sessionId');

if (!sessionId) {
  sessionId = Math.random().toString(36).substring(2);
  sessionStorage.set('sessionId', sessionId);
}

@Service({
  url: '/api',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Session-Id': sessionId,
  }
})
export class BaseAPI extends API {
  async catch({ response, store }) {
    if (response.status === 429) {
      store.dispatch(showNotification({
        type: 'error',
        title: i18n.t('Ліміт кількості запитів'),
        message: i18n.t('Ви перевищили максимальну кількість запитів')
      }));

      return response;
    }

    if (!response.ok && ![400, 401].includes(response.status)) {
      store.dispatch(showNotification({
        type: 'error',
        title: i18n.t('Технічна помилка'),
        message: i18n.t('Виникла технічна помилка, повторіть запит пізніше')
      }));

      return response;
    }

    return response;
  }
}

export class InnerAPI extends BaseAPI {
  @Endpoint({
    path: '/token/refresh',
    method: HttpMethod.GET,
    headers: {
      'Content-Type': undefined,
    },
    handleCatch: false,
  })
  async refreshToken() {}

  async catch({ request, response, store }) {
    if (!request.handleCatch) {
      return response;
    }

    if (response.status === 401) {
      const result = await this.refreshToken();

      if (result.status === 401) {
        this.store.dispatch(replace('/login'));
        return response;
      }

      return this.fetch(request, { store });
    }

    return super.catch({ response, store });
  }
}
