import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { BEM } from '@bikejs/react-bem';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';

import { selectUserInfo } from 'common/redux/cabinet/selectors';
import { formatPhoneNumber } from 'common/helpers/string.helper';

import Icon from 'components/Icon';

import {MenuBlock} from 'common/blocks/Menu';

import './index.scss';

@withRouter
@connect(state => ({
  user: selectUserInfo(state),
}))
@withTranslation()
@BEM('aside-block')
export class AsideBlock extends React.PureComponent {
  static displayName = 'AsideBlock';

  state = {
    isOpen: false,
  }

  onToggleMenu(isOpen = !this.state.isOpen) {
    this.setState({ isOpen });
  }

  render() {
    const { user, match, t, bem } = this.props;
    const { isOpen } = this.state;

    const isEnergy = match.path.includes('/energy');

    return (
      <section className={bem()}>
        <Link to={`/cabinet/${match.params.companyId}/${isEnergy ? 'energy' : 'gas'}`} className={bem('header')}>
          <div className={bem('icon', { energy: isEnergy })} />
          <div className={bem('header-text')}>TAS Energy</div>
        </Link>
        <article className={bem('menu', {open: isOpen})}>
          <MenuBlock onClick={() => this.onToggleMenu(false)} />
        </article>
        <footer className={bem('footer')}>
          {user.manager && (
            <div className={bem('manager')}>
              <div className={bem('text')}>
                {t('Зв\'язатись з персональним менеджером')}
              </div>
              <div className={bem('manager-name')}>
                {user.manager.name}
              </div>
              <a className={bem('phone')} href={`tel:+${user.manager.phone}`}>
                {formatPhoneNumber(`+${user.manager.phone}`)}
              </a>
            </div>
          )}
          {/*<div className={bem('text')}>*/}
          {/*  {t('Зв\'язатись з кол-центром')}*/}
          {/*</div>*/}
          {/*<a className={bem('phone')} href="tel:+0800755205">*/}
          {/*  0 800-755-205*/}
          {/*</a>*/}
        </footer>
        <button className={bem('mobile')} onClick={() => this.onToggleMenu()}>
          <Icon name="menu" />
        </button>
      </section>
    );
  }
}
