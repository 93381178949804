import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import Icon from '../Icon';

import './index.scss';

@BEM('timer-component')
export default class Timer extends React.PureComponent {
  static displayName = 'Timer';

  static propTypes = {
    time: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    stoke: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    color: PropTypes.string,
    bg: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    time: 5,
    stoke: 2,
    size: 32,
    color: '#0648b8',
    bg: '#f5f5f5',
    onClick: () => {},
  };

  state = {
    current: this.props.time,
    animated: true,
  };

  interval = null;

  $animation = React.createRef();

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onStartTimer() {
    this.interval = setInterval(() => {
      this.setState({ current: this.state.current - 1 })
    }, 1000);
  }

  onEndTimer() {
    this.setState({ current: 0 });
    clearInterval(this.interval);
  }

  onClick() {
    const { time, onClick } = this.props;

    onClick();

    this.setState({ current: time, animated: false });

    void this.$animation.current.offsetWidth;

    setTimeout(() => {
      this.setState({ animated: true });
    }, 100);
  }

  render() {
    const { time, bg, color, size, stoke, bem } = this.props;
    const { current, animated } = this.state;

    return (
      <div className={bem()} style={{ width: size, height: size, color }}>
        <svg viewBox="0 0 36 36">
          <path
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={bg}
            strokeWidth={stoke}
            strokeDasharray="100, 100"
          />
          <path
            className={bem('progress', { animated })}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={color}
            strokeWidth={stoke}
            style={{ animationDuration: `${time}s` }}
            onAnimationStart={() => this.onStartTimer()}
            onAnimationEnd={() => this.onEndTimer()}
            ref={this.$animation}
          />
        </svg>

        <div className={bem('time')}>
          {current || (
            <div className={bem('control')} onClick={() => this.onClick()}>
              <Icon name="autorenew" size={18} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
