import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('icon')
export default class Icon extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  };

  static defaultProps = {
    color: 'inherit',
    size: 'inherit'
  };

  render() {
    const { name, color, size, bem } = this.props;

    return (
      <span className={bem()} style={{ color, fontSize: size }}>{name}</span>
    );
  }
}
