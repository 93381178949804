import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { showNotification } from 'components/Notifications/redux';
import { Endpoint, HttpMethod } from 'common/plugins/colibri';

import { InnerAPI } from 'common/redux';
import { Cabinet } from 'common/redux/cabinet';
import i18n from 'common/services/i18n.service';

export const NAMESPACE = '@energy';

const setData = createAction(`${NAMESPACE}/SET_DATA`);
const setBalance = createAction(`${NAMESPACE}/SET_BALANCE`);
const setCharts = createAction(`${NAMESPACE}/SET_CHARTS`);
const setRenominations = createAction(`${NAMESPACE}/SET_RENOMINATIONS`);
const setDocuments = createAction(`${NAMESPACE}/SET_DOCUMENTS`);
const setManualDocuments = createAction(`${NAMESPACE}/SET_MANUAL_DOCUMENTS`);
const setActs = createAction(`${NAMESPACE}/SET_ACTS`);

class EnergyAPI extends InnerAPI {
  async getInitialData() {
    await Cabinet.getCompanies();
    await this.getData().catch(e => console.log(111, e));
  }

  @Endpoint({
    path: '/cabinet/electric',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getData(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setData(response.body));
  }

  @Endpoint({
    path: '/cabinet/service_acts'
  })
  async getActs(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setActs(response.body));
  }

  @Endpoint({
    path: '/cabinet/electric/balance',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getBalance(response) {
    if (!response.ok) {
      return response;
    }

    this.store.dispatch(setBalance(response.body));
  }

  @Endpoint({
    path: '/cabinet/electric/renominations',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getRenominations(response) {
    if (!response.ok) {
      this.store.dispatch(setRenominations([
        {
          "id": "100",
          "date": "2020-12-12",
          "eic_code_tko": "2837NW978346",
          "price": "100.00",
          "group": "A",
          "volume": "100.00",
          "status": "Новий",
          "is_create_renomination": false
        }
      ]));
      return response;
    }

    this.store.dispatch(setRenominations(response.body));
  }

  @Endpoint({
    path: '/cabinet/electric/renomination',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async saveReomination(response) {
    if (!response.ok) {
      return response;
    }
  }

  @Endpoint({
    path: '/cabinet/electric/upload_fact',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async uploadFact(response) {
    if (!response.ok) {
      this.store.dispatch(showNotification({
        type: 'error',
        title: i18n.t('Помилка завантаження файлу'),
        message: i18n.t('Перевірте розширення та вміст файлу')
      }));

      return Promise.reject(response);
    }

    this.getRenominations();

    this.store.dispatch(showNotification({
      type: 'success',
      title: i18n.t('Чудово!'),
      message: i18n.t('Файл успішно завантажено')
    }));
  }

  @Endpoint({
    path: '/cabinet/electric/nomination',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async saveNomination(response) {
    if (!response.ok) {
      return response;
    }

    await this.getData();
  }

  @Endpoint({
    path: '/cabinet/electric/balance_detail_act',
    type: 'BLOB',
  })
  async getBalanceAct(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    const url = URL.createObjectURL(response.body);
    window.open(url);
  }

  @Endpoint({
    path: '/cabinet/electric/docs'
  })
  async getDocuments(response) {
    if (!response.ok) {
      return response;
    }

    this.store.dispatch(setDocuments(response.body));
  }

  @Endpoint({
    path: '/public/site-electric-documents'
  })
  async getManualDocuments(response) {
    if (!response.ok) {
      return response;
    }

    this.store.dispatch(setManualDocuments(response.body))
  }

  @Endpoint({
    path: '/cabinet/electric/charts'
  })
  async getCharts(response) {
    if (!response.ok) {
      this.store.dispatch(setCharts({
        "consumption": {
          "date_plan": "2020-12-12",
          "date_fact": "2020-12-12",
          "total_plan": "100.00",
          "total_fact": "100.00",
          "power": {
            "total": "100.00",
            "rest": "100.00",
            "rate": "100.00"
          },
          "date_min": "2020-12-12",
          "date_max": "2020-12-12",
          "data": [
            {
              "date": "2020-12-12",
              "plan": "100.00",
              "fact": "100.00"
            }
          ]
        },
        "purchase": {
          "total_plan": "100.00",
          "total_fact": "100.00",
          "power": {
            "total": "100.00",
            "rest": "100.00",
            "rate": "100.00"
          },
          "date_min": "2020-12-12",
          "date_max": "2020-12-12",
          "data": [
            {
              "date": "2020-12-12",
              "plan": "100.00",
              "fact": "100.00"
            }
          ]
        },
        "positive_balance": {
          "data": [
            {
              "date": "2020-12-12",
              "volume": "100.00",
              "percent": "100.00",
              "hours": "10"
            }
          ],
          "volume": "100.00"
        },
        "negative_balance": {
          "data": [
            {
              "date": "2020-12-12",
              "volume": "100.00",
              "percent": "100.00",
              "hours": "10"
            }
          ],
          "volume": "100.00"
        },
        "date_min": "2020-12-12",
        "date_max": "2020-12-12"
      }));
      return response;
    }

    this.store.dispatch(setCharts(response.body))
  }

  async before({ request }) {
    const { router } = this.store.getState();

    const companyId = router.location.pathname.replace(/.*\/cabinet\/(\d+).*/, '$1');

    if (!companyId) {
      return request;
    }

    request.headers = {
      ...request.headers,
      'x-client_id': companyId,
    }

    return request;
  }
}

export const EnergyService = new EnergyAPI();

export const energyReducer = handleActions({
  [setData]: (state, action) => ({
    ...state,
    data: {
      ...action.payload,
      user: {
        id: action.payload.id,
        name: action.payload.company.name,
        balance: action.payload.balance,
        balanceDate: action.payload.balance_date || action.payload.date,
        userDetails: action.payload.user_detail,
        contract: action.payload.contract_uri,
        manager: action.payload.manager,
        documentFlow: action.payload.document_flow,
        code: action.payload.edrpou,
        addressLegal: action.payload.legal_address,
        addressPost: action.payload.post_address,
        properties: action.payload.properties,
      }
    },
  }),
  [setManualDocuments]: (state, { payload }) => ({
    ...state,
    manualDocuments: payload,
  }),
  [setBalance]: (state, action) => ({
    ...state,
    balance: action.payload,
  }),
  [setRenominations]: (state, action) => ({
    ...state,
    renominations: action.payload,
  }),
  [setCharts]: (state, { payload }) => ({
    ...state,
    charts: {
      consumption: {
        plan: {
          date: moment(payload.consumption.date_plan, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          total: payload.consumption.total_plan,
        },
        fact: {
          date: moment(payload.consumption.date_fact, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          total: payload.consumption.total_fact,
        },
        data: payload.consumption.data.map(item => ({
          ...item,
          date: moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        })),
        power: payload.consumption.power,
        dates: {
          min: payload.date_min,
          max: payload.date_max,
        }
      },
      purchase: {
        plan: {
          total: payload.purchase.total_plan,
        },
        fact: {
          total: payload.purchase.total_fact,
        },
        data: payload.purchase.data.map(item => ({
          ...item,
          date: moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        })),
        power: payload.purchase.power,
        dates: {
          min: payload.purchase.date_min,
          max: payload.purchase.date_max,
        }
      },
      positiveBalance: {
        data: payload.positive_balance.data.map(item => ({
          ...item,
          date: moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        })),
        volume: payload.positive_balance.volume,
      },
      negativeBalance: {
        data: payload.negative_balance.data.map(item => ({
          ...item,
          date: moment(item.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        })),
        volume: payload.negative_balance.volume,
      },
    },
  }),
  [setDocuments]: (state, { payload }) => ({
    ...state,
    documents: payload,
  }),
  [setActs]: (state, { payload }) => ({
    ...state,
    acts: payload.map((item, index) => ({
      ...item,
      id: item.id || index + 1,
      volume: item.volume || 0,
    })),
  }),
}, {
  data: {
    banner: {},
    company: {},
    nominations: [],
    prices: [],
    user: {
      id: 0,
      name: '-- -- --',
      balance: 0,
      balanceDate: '--',
      bill: undefined,
      manager: undefined,
      tariff: {
        name: {
          uk: 'Місячний',
          ru: 'Місячний',
        },
        price: 0,
        expired: '--',
      }
    },
    manager: undefined,
  },
  documents: [],
  manualDocuments: [],
  renominations: [],
  charts: {
    consumption: {
      plan: {
        date: '',
        total: 0,
      },
      fact: {
        date: '',
        total: 0,
      },
      power: {
        total: 0,
        rest: 0,
        rate: 0,
      },
      dates: {
        min: null,
        max: null,
      },
      data: [],
    },
    purchase: {
      plan: {
        total: 0,
      },
      fact: {
        total: 0,
      },
      power: {
        total: 0,
        rest: 0,
        rate: 0,
      },
      dates: {
        min: null,
        max: null,
      },
      data: [],
    },
    positiveBalance: {
      data: [],
      volume: 0,
    },
    negativeBalance: {
      data: [],
      volume: 0,
    },
  },
  balance: {
    "date": "2020-12-12",
    "calculation_balance": 0,
    "current_period_debt": {
      "natural_gas_prepayment": 0,
      "ordered_power_service": 0
    },
    "previous_period_debt": {
      "natural_gas_debt": 0,
      "ordered_capacity_exceed": 0,
      "ordered_power_service": 0
    }
  },
  acts: [],
});
