import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { connectNotifications } from 'components/Notifications/redux';
import { connectLoader } from 'components/Loader/redux';
import { connectModal, NAMESPACE as MODAL_NAMESPACE } from 'components/Modal/redux';

import { i18nReducer } from 'common/services/i18n.service';
import { energyReducer, NAMESPACE as ENERGY } from 'common/redux/energy';
import { cabinetReducer, NAMESPACE as CABINET } from 'common/redux/cabinet';

export const createRootReducer = ({ history }) => combineReducers({
  router: connectRouter(history),
  loader: connectLoader(),
  notifications: connectNotifications(),
  [MODAL_NAMESPACE]: connectModal(),
  i18n: i18nReducer,
  [ENERGY]: energyReducer,
  [CABINET]: cabinetReducer,
});
