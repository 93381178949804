import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';
import { matchPath } from 'react-router';

import { Endpoint, HttpMethod } from 'common/plugins/colibri';
import i18n from 'common/services/i18n.service';
import { showNotification } from 'components/Notifications/redux';

import { InnerAPI } from 'common/redux';
import { selectCompaniesList } from 'common/redux/cabinet/selectors';

export const NAMESPACE = '@cabinet';

const setUserData = createAction(`${NAMESPACE}/SET_USER_DATA`);
const setPriceChartData = createAction(`${NAMESPACE}/SET_PRICE_CHART_DATA`)
const setBalance = createAction(`${NAMESPACE}/SET_BALANCE`);
const setBills = createAction(`${NAMESPACE}/SET_BILLS`);
const setAgreements = createAction(`${NAMESPACE}/SET_AGREEMENTS`);
const setActs = createAction(`${NAMESPACE}/SET_ACTS`);
const setCompaniesList = createAction(`${NAMESPACE}/SET_COMPANIES_LIST`);

class CabinetAPI extends InnerAPI {
  async getInitialData() {
    await this.getCompanies();
    await this.getUserData();
  }

  getCompanyId() {
    const state = this.store.getState();
    const companies = selectCompaniesList(state);

    const match = matchPath(state.router.location.pathname, {
      path: '/cabinet/:companyId',
    });

    const companyId = match ? match.params.companyId : companies[0].id;

    return companyId;
  }

  @Endpoint({
    path: '/cabinet/info',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getUserData(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    try {
      this.store.dispatch(setUserData(response.body));
    } catch (e) {
      console.log('Error DTO:', e);
    }

    return response.body;
  }

  @Endpoint({
    path: '/cabinet/companies',
    type: 'JSON',
    method: HttpMethod.GET,
  })
  async getCompanies(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setCompaniesList(response.body));
  }

  @Endpoint({
    path: '/cabinet/chart_price'
  })
  async getPriceChart(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setPriceChartData(response.body));
  }

  @Endpoint({
    path: '/cabinet/bills'
  })
  async getBills(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setBills(response.body));
  }

  @Endpoint({
    path: '/cabinet/agreements'
  })
  async getAgreements(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setAgreements(response.body));
  }

  @Endpoint({
    path: '/cabinet/service_acts'
  })
  async getActs(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setActs(response.body));
  }

  @Endpoint({
    path: '/cabinet/balance'
  })
  async getBalance(response) {
    if (!response.ok) {
      return Promise.reject(response);
    }

    this.store.dispatch(setBalance(Array.isArray(response.body) ? response.body : [response.body]));
  }

  @Endpoint({
    path: '/cabinet/balance_act',
    type: 'BLOB',
  })
  async getBalanceAct(response) {
    const $link = document.createElement('a');

    $link.download = response.headers['content-disposition'].replace(/.*filename=(.*)/g, '$1');
    $link.href = URL.createObjectURL(response.body);

    document.body.appendChild($link);
    $link.click();
    $link.remove();
  }

  @Endpoint({
    path: '/installment/pay',
    type: 'JSON',
    method: HttpMethod.POST,
  })
  async installmentPay(response) {
    if (!response.ok) {
      this.store.dispatch(showNotification({
        type: 'error',
        title: i18n.t('Технічна помилка'),
        message: i18n.t('Виникла технічна помилка, повторіть запит пізніше')
      }));
    }

    if (response.ok && !response.body.redirect_url) {
      this.store.dispatch(showNotification({
        type: 'success',
        title: i18n.t('Заявка успішно відправлена'),
        message: i18n.t('Очікуйте повідомлення на своєму телефоні')
      }));
    }

    return response.body;
  }

  async before({ request }) {
    const { router } = this.store.getState();

    const match = matchPath(router.location.pathname, {
      path: '/cabinet/:companyId',
    });

    if (!match || !match.params) {
      return request;
    }

    request.headers = {
      ...request.headers,
      'x-client_id': match.params.companyId,
    }

    return request;
  }
}

export const Cabinet = new CabinetAPI();

export const cabinetReducer = handleActions({
  [setCompaniesList]: (state, action) => ({
    ...state,
    companies: action.payload,
  }),
  [setUserData]: (state, { payload }) => ({
    ...state,
    user: {
      id: payload.id,
      name: payload.pib,
      balance: payload.balance,
      balanceDate: payload.balance_date,
      contract: payload.contract_uri,
      manager: payload.manager,
      documentFlow: payload.document_flow,
      code: payload.edrpou,
      addressLegal: payload.legal_address,
      addressPost: payload.post_address,
      properties: payload.properties,
      tariff: payload.tariff ? {
        name: payload.tariff.name,
        price: payload.tariff.price,
        expired: payload.tariff.expiration_date,
      } : state.user.tariff,
      bill: {
        id: payload.bill.id,
        billUrl: payload.bill.liqpay_url,
        amount: payload.bill.to_pay,
        date: moment(payload.bill.create_at, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      },
      userDetails: payload.user_detail,
    }
  }),
  [setPriceChartData]: (state, { payload }) => ({
    ...state,
    priceChart: payload,
  }),
  [setBills]: (state, { payload }) => ({
    ...state,
    bills: payload.map(item => ({
      id: item.id,
      name: item.name,
      url: item.url,
      status: item.status,
      month: item.month,
      year: item.year,
      eic: item.eic_code,
      created: item.created,
    }))
  }),
  [setAgreements]: (state, { payload }) => ({
    ...state,
    agreements: payload.map(item => ({
      id: item.id,
      name: item.name,
      url: item.url,
      status: item.status,
      month: item.month,
      year: item.year,
      eic: item.eic_code,
      created: item.created,
    }))
  }),
  [setActs]: (state, { payload }) => ({
    ...state,
    acts: payload.map((item, index) => ({
      ...item,
      id: item.id || index + 1,
      volume: item.volume || 0,
      created: '2020-12-12',
    })),
  }),
  [setBalance]: (state, { payload }) => ({
    ...state,
    balance: payload.map(item => ({
      id: item.id,
      account: item.account_number,
      eic: item.eic_code,
      address: item.address,
      billUrl: item.liqpay_url,
      pdfUrl: item.bill_url,
      amount: item.to_pay,
      volume: item.volume,
      tariff: item.tariff,
      bills: item.bills.map(bill => ({
        id: bill.id,
        month: bill.month,
        balanceBegin: bill.balance_begin,
        volume: bill.volume,
        tariff: bill.tariff,
        amount: bill.amount,
        pay: bill.to_pay,
        payed: bill.payed,
        payedAt: bill.payed_at,
        balanceEnd: bill.balance_end,
        pdfUrl: bill.bill_url,
      }))
    }))
  }),
}, {
  user: {
    id: 0,
    name: '-- -- --',
    balance: 0,
    balanceDate: '--',
    bill: undefined,
    manager: undefined,
    tariff: {
      name: {
        uk: 'Місячний',
        ru: 'Місячний',
      },
      price: 0,
      expired: '--',
    }
  },
  companies: [],
  priceChart: [],
  balance: [],
  bills: [],
  agreements: [],
  acts: [],
})
