import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { renderRoutes, matchRoutes } from 'react-router-config';

import { BEM } from '@bikejs/react-bem';

import { H2 } from 'components/Title';

import { AuthorizationLayout } from 'common/layouts/Authorization';

import './index.scss';

@withTranslation()
@BEM('registration-layout')
export class RegistrationLayout extends React.PureComponent {
  static displayName = 'RegistrationLayout';

  render() {
    const { route, bem, t } = this.props;

    return (
      <React.Fragment>
        <Helmet defaultTitle="Реєстрація - TAS Energy">
          <meta name="description" content="Реєстрація - TAS Energy" />
        </Helmet>
        <AuthorizationLayout
          image="/assets/images/registration-color-bg.png"
        >
          <header className={bem('title')}>
            <H2>{t('Реєстрація')}</H2>
          </header>
          {renderRoutes(route.routes)}
        </AuthorizationLayout>
      </React.Fragment>
    );
  }
}
