import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { connect } from 'react-redux';
import { withRouter, generatePath, Redirect } from 'react-router';

import InputSwitch from 'components/InputSwitch';

import { selectBillInfo, selectCompaniesList } from 'common/redux/cabinet/selectors';

import {numberFormatter} from 'common/helpers/string.helper';

import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import './index.scss';

const TOGGLE_TYPES = {
  gas: 'electricity',
  electricity: 'gas',
};

@withRouter
@connect((state) => ({
  companies: selectCompaniesList(state),
}))
@withTranslation()
@BEM('header-block')
export class HeaderBlock extends React.PureComponent {
  static displayName = 'HeaderBlock';

  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      tariff: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  }

  onChangeCompany(companyId) {
    const path = this.props.location.pathname.replace(/^\/cabinet\/(\d+)/, '/cabinet/:companyId');
    window.location.href = generatePath(path, { companyId });
  }

  onChangeType() {
    const { match, history } = this.props;

    if (match.path.includes('/energy')) {
      history.push(`/cabinet/${match.params.companyId}/gas`);
      return;
    }

    history.push(`/cabinet/${match.params.companyId}/energy`);
  }

  render() {
    const { user, bill, companies, match, t, bem } = this.props;
    const currentCompany = companies.find(item => item.id === Number(match.params.companyId));

    if (!currentCompany) {
      return <Redirect to="/login" />;
    }

    return (
      <section className={bem()}>
        <ul className={bem('tariff')}>
          {/*<li className={bem('tariff-item')}>*/}
          {/*  <div className={bem('tariff-value')}>{user.tariff.name}</div>*/}
          {/*  <div className={bem('tariff-label')}>{t('Ваш тариф')}</div>*/}
          {/*</li>*/}
          {/*<li className={bem('tariff-item')}>*/}
          {/*  <div className={bem('tariff-value')}>*/}
          {/*    {numberFormatter(user.tariff.price)} {t('грн.')}*/}
          {/*  </div>*/}
          {/*  <div className={bem('tariff-label')}>*/}
          {/*    {t('Ціна до {{date}}', {date: user.tariff.expired})}*/}
          {/*  </div>*/}
          {/*</li>*/}
          <li className={bem('tariff-item')}>
            <div className={bem('tariff-value')}>
              {numberFormatter(user.balance)} {t('грн.')}
            </div>
            <div className={bem('tariff-label')}>
              {bill.amount < 0 ? t('Борг') : t('Передплата')} {' '}
              {t('станом на {{date}}', {date: user.balanceDate})}
            </div>
          </li>
        </ul>
        {currentCompany.roles.includes('GAS') && currentCompany.roles.includes('ELECTRICITY') && (
          <div className={bem('switch')}>
            <span>{t('Газ')}</span>
            <div className={bem('switch-input')}>
              <InputSwitch
                theme="blue"
                input={{
                  name: 'switch-account',
                  type: 'checkbox',
                  checked: match.path.includes('/energy'),
                  onChange: () => this.onChangeType(),
                }}
              />
            </div>
            <span>{t('Електроенергія')}</span>
          </div>
        )}
        <div className={bem('user')}>
          <div className={bem('company')}>
            <DropDown
              nowrap
              active={match.params.companyId}
              margin={{
                top: 23,
                left: -16,
              }}
              options={companies.map(company => ({
                label: company.name || 'None',
                value: String(company.id),
              }))}
              onChange={({value}) => this.onChangeCompany(value)}
              controlRender={({ active }) => active ? (
                <div className={bem('company-label')}>
                  {active.label}
                </div>
              ) : null}
            />
          </div>
          <div className={bem('icon')}>
            <Icon name="account_circle" />
          </div>
        </div>
      </section>
    );
  }
}
