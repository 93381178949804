import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import moment from 'moment';

import Box from 'components/Box';
import Title from 'components/Title';
import Table from 'components/Table';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import Loader from 'components/Loader';

import { fetch } from 'common/services/fetch.service';
import { EnergyService } from 'common/redux/energy';
import { selectActs } from 'common/redux/energy/selectors';

import { numberFormatter } from 'common/helpers/string.helper';

import { AccountsFilterForm } from 'common/forms/AccountsFilter';

import './index.scss';

@fetch({
  acts: [selectActs, ({ location: { query = {} } }) => EnergyService.getActs({ query })]
}, null, {
  loader: 'acts',
  watchQuery: true,
})
@withTranslation()
@BEM('acts-page')
export class ActsEnergyPage extends React.PureComponent {
  static displayName = 'ActsEnergyPage';

  onUpdate({month, year}) {
    const { location, history } = this.props;

    history.push({...location, query: { month, year }});
  }

  render() {
    const { acts, bem, t } = this.props;

    return (
      <Box>
        <section className={bem()}>
          <header className={bem('header')}>
            <Title size="h3">{t('Акти надання послуг')}</Title>

            <div className={bem('filter')}>
              <AccountsFilterForm
                onSubmit={(values) => this.onUpdate(values)}
                onReset={(values) => this.onUpdate(values)}
              />
            </div>
          </header>
          <article className={bem('main')}>
            <Table
              minWidth={600}
              theme="inset"
              columns={[
                {
                  id: 'id',
                  width: '46',
                  label: t('№')
                },
                {
                  id: 'month',
                  label: t('Місяць')
                },
                {
                  id: 'volume',
                  label: t('Об\'єм')
                },
                {
                  id: 'created',
                  width: '120',
                  label: t('Створено')
                },
                {
                  id: 'link',
                  align: 'right',
                  width: 120,
                  label: t('Завантажити')
                },
              ]}
              rows={acts.map(item => ({
                id: item.id,
                month: item.month,
                volume: `${numberFormatter(item.volume)} м³`,
                created: moment(item.created, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                link: (
                  <a className={bem('download')} target="_blank" href={item.url}>
                    <Icon name="file_download" />
                  </a>
                )
              }))}
              placeholder={t('Немає актів')}
            />
          </article>
        </section>
        <Loader name="acts" color="white" />
      </Box>
    );
  }
}
