import { createSelector } from 'reselect';
import moment from 'moment';

import { MONTHS } from 'common/helpers/months.helper';

import { NAMESPACE } from './index';
import { getShortName } from 'common/helpers/string.helper';

export const energy = (state) => state[NAMESPACE];
export const lang = (state) => state.i18n.lang;

export const selectUserData = createSelector(
  energy,
  ({ data }) => data.user,
);

export const selectUserManager = createSelector(
  energy,
  ({ data }) => data.manager,
);

export const selectUserTariffData = createSelector(
  energy,
  ({ data }) => data.tariff,
);

export const selectUserBalanceAndTariff = createSelector(
  energy,
  ({ data }) => ({
    balance: data.balance,
    date: data.date,
    tariff: data.tariff.price,
    month: MONTHS[moment(data.date, 'MM.YYYY').month() + 1].toLowerCase(),
    canOrder: data.order_available,
    bill: {
      ...(data.last_bill || {}),
      month: data.last_bill ? MONTHS[moment(data.last_bill.date, 'YYYY-MM-DD').month() + 1].toLowerCase() : '',
    },
  }),
);

export const selectChartConsumptionData = createSelector(
  energy,
  ({ charts }) => charts.consumption,
);

export const selectPurchaseData = createSelector(
  energy,
  ({ charts }) => charts.purchase,
);

export const selectPositiveBalance = createSelector(
  energy,
  ({ charts }) => charts.positiveBalance,
);

export const selectNegativeBalance = createSelector(
  energy,
  ({ charts }) => charts.negativeBalance,
);

export const selectManualDocuments = createSelector(
  energy,
  ({ manualDocuments }) => manualDocuments,
);

export const selectDocuments = createSelector(
  energy,
  ({ documents }) => documents,
);

export const selectCompanyPoints = createSelector(
  energy,
  ({ data }) => data.company.exit_points || [],
);

export const selectBalance = createSelector(
  energy,
  ({ balance }) => balance,
);

export const selectPrices = createSelector(
  energy,
  ({ data }) => data.prices,
);

export const selectBannerData = createSelector(
  energy,
  ({ data }) => data.banner,
);

export const selectRenominations = createSelector(
  energy,
  ({ renominations }) => renominations,
);

export const selectOrders = createSelector(
  energy,
  ({ data }) => ({
    nominations: data.nominations,
    consumption: data.nominations.reduce((target, nomination) => [...target, ...nomination.consumption], []),
  }),
);

export const selectActs = createSelector(
  energy,
  ({ acts }) => acts,
);

export const selectHeaderInfo = createSelector(
  energy,
  lang,
  ({ data }, lang) => ({
    user: {
      ...data.user,
      name: getShortName(data.user.name),
      tariff: {
        ...data.user.tariff,
      },
      balance: data.balance,
      balanceDate: data.balance_date || data.date,
    },
    bill: {
      ...(data.last_bill || {}),
      month: data.last_bill ? MONTHS[moment(data.last_bill.date, 'YYYY-MM-DD').month() + 1].toLowerCase() : '',
    },
  }),
);

