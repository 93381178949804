import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import OuterClick from '../OuterClick';
import InputText from '../InputText';
import Icon from '../Icon';

import './index.scss';

@BEM('input-select-component')
export default class InputSelect extends React.PureComponent {
  static displayName = 'InputSelect';

  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      onChange: PropTypes.func,
    }),
    render: PropTypes.func,
    children: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  static getDerivedStateFromProps(nextProps) {
    const active = nextProps.options.find(({ value }) => value === nextProps.input.value);

    return {
      innerInputValue: active ? active.label : '',
    };
  }

  state = {
    open: false,
    innerInputValue: ''
  };

  onSelect(e) {
    this.props.input.onChange(e.target.dataset.value);
    this.setState({ open: false });
  }

  onToggle(open = !this.state.open) {
    if (open) {
      this.props.input.onFocus(this.props.input.value);
    } else if (open !== this.state.open) {
      this.props.input.onBlur(this.props.input.value);
    }

    this.setState({ open });
  }

  render() {
    const { label, options, meta, loading, bem } = this.props;
    const { innerInputValue, open } = this.state;

    return (
      <OuterClick onClick={() => this.onToggle(false)}>
        <div className={bem()}>
          <div className={bem('input', { loading })} onClick={() => this.onToggle()}>
            <InputText label={label} input={{ value: innerInputValue }} meta={{ ...meta, active: false }} readOnly loading={loading} />
          </div>
          <ul
            className={bem('options', { open })}
            onClick={(e) => this.onSelect(e)}
          >
            {options.map(item => (
              <li className={bem('option')} key={item.value} data-value={item.value}>
                {item.label}
              </li>
            ))}
          </ul>
          <div className={bem('arrow', { open })}>
            <Icon name="expand_more" size={18} />
          </div>
        </div>
      </OuterClick>
    );
  }
}
