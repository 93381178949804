import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BEM } from '@bikejs/react-bem';

import { CircleProgress } from '../Progress';

import './index.scss';

@connect((state, props) => ({
  loader: state.loader[props.name],
}))
@BEM('loader-component')
export default class Loader extends React.PureComponent {
  static displayName = 'Loader';

  static propTypes = {
    name: PropTypes.string,
    color: PropTypes.oneOf(['black', 'white']),
    fixed: PropTypes.bool,
    size: PropTypes.number,
  };

  static defaultProps = {
    name: 'global',
    color: 'black',
    fixed: false,
    size: 74,
  };

  render() {
    const { loader, color, size, fixed, bem } = this.props;

    return (
      <div className={bem({ show: loader, color, fixed })}>
        <div className={bem('progress')}>
          <CircleProgress size={size} />
        </div>
      </div>
    );
  }
}
