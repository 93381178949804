import React from 'react';
import PropTypes from 'prop-types';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import { BEM } from '@bikejs/react-bem';

import 'react-dates/initialize';

import Button from '../Button';

import '../Calendar/styles/react-dates.css';
import './index.scss';

const [FROM_DATE, TO_DATE] = ['startDate', 'endDate'];

@BEM('period-component')
export default class Period extends React.Component {
  static displayName = 'Calendar';

  static propTypes = {
    period: PropTypes.shape({
      from: PropTypes.any,
      to: PropTypes.any,
    }),
    format: PropTypes.string,
    onChange: PropTypes.func,
    lang: PropTypes.oneOf(['uk', 'ru']),
  };

  static defaultProps = {
    period: {
      from: new Date(),
      to: new Date().setMonth(new Date().getMonth() + 1),
    },
    format: 'DD.MM.YYYY',
    onChange: () => {},
    lang: 'uk',
  };

  state = {
    open: false,
    focusedInput: FROM_DATE,
    period: this.props.period,
  };

  constructor(...args) {
    super(...args);

    moment.locale(this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      moment.locale(this.props.lang);
    }
  }

  togglePicker(open = !this.state.open) {
    this.setState({ open });
  }

  onChange(dates) {
    const { focusedInput } = this.state;

    if (focusedInput === FROM_DATE || !dates[TO_DATE]) {
      this.setState({
        period: { from: dates[FROM_DATE].toDate(), to: undefined },
      });

      return;
    }

    this.setState({
      period: { from: dates[FROM_DATE].toDate(), to: dates[TO_DATE].toDate() },
      open: false,
    }, () => {
      this.props.onChange(this.state.period);
    })
  }

  render() {
    const { format, bem } = this.props;
    const { period, open, focusedInput } = this.state;

    return (
      <div className={bem()}>
        <div className={bem('label')}>
          <Button theme="link" size="small" onClick={() => this.togglePicker(true)}>
            {moment(period.from).format(format)} - {moment(period.to).format(format)}
          </Button>
        </div>
        {open && (
          <DayPickerRangeController
            startDate={moment(period.from)}
            endDate={period.to && moment(period.to)}
            isFocused={open}
            numberOfMonths={2}
            firstDayOfWeek={1}
            onFocusChange={(focused) => this.setState({ focusedInput: focused || FROM_DATE })}
            onDatesChange={dates => this.onChange(dates)}
            onOutsideClick={() => this.togglePicker(false)}
            transitionDuration={0}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
          />
        )}
      </div>
    );
  }
}
