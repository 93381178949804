import React from 'react';
import { Redirect } from 'react-router';

import { CabinetLayout } from 'common/layouts/Cabinet';

import { MainPage } from 'common/pages/Main';
import { AccountsPage } from 'common/pages/Accounts';
import { AdditionalPage } from 'common/pages/Additional';
import { ActsPage } from 'common/pages/Acts';
import { NotFoundPage } from 'common/pages/NotFound';

import { CabinetEnergyLayout } from 'common/layouts/CabinetEnergy';

import { MainEnergyPage } from 'common/pages/MainEnergy';
import { ActsEnergyPage } from 'common/pages/ActsEnergy';

import { authRoutes } from './auth';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: '/cabinet/:companyId',
    exact: true,
    component: ({ match: { params } }) => <Redirect to={`/cabinet/${params.companyId}/gas`} />,
  },
  ...authRoutes,
  {
    path: '/cabinet/:companyId/gas',
    component: CabinetLayout,
    routes: [
      {
        path: '/cabinet/:companyId/gas',
        exact: true,
        component: MainPage,
      },
      {
        path: '/cabinet/:companyId/gas/accounts',
        exact: true,
        component: AccountsPage,
      },
      {
        path: '/cabinet/:companyId/gas/additional',
        exact: true,
        component: AdditionalPage,
      },
      {
        path: '/cabinet/:companyId/gas/acts',
        exact: true,
        component: ActsPage,
      }
    ],
  }, {
    path: '/cabinet/:companyId/energy',
    component: CabinetEnergyLayout,
    routes: [
      {
        path: '/cabinet/:companyId/energy',
        exact: true,
        component: MainEnergyPage,
      },
      {
        path: '/cabinet/:companyId/energy/accounts',
        exact: true,
        component: AccountsPage,
      },
      {
        path: '/cabinet/:companyId/energy/additional',
        exact: true,
        component: AdditionalPage,
      },
      {
        path: '/cabinet/:companyId/energy/acts',
        exact: true,
        component: ActsEnergyPage,
      }
    ],
  }, {
    path: '/cabinet',
    exact: true,
    component: () => <Redirect to="/login" />,
  }, {
    path: '/404',
    exact: true,
    render: ({ staticContext = {} }) => {
      staticContext.status = 404;

      return (
        <NotFoundPage />
      );
    }
  }, {
    render: ({ staticContext = {} }) => {
      staticContext.status = 404;

      return (
        <NotFoundPage />
      );
    }
  }
];
