import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { matchType } from 'common/helpers/router.helper';
import i18n from 'common/services/i18n.service';

import Icon from 'components/Icon';
import { showModal } from 'components/Modal/redux';

import { Auth } from 'common/redux/auth';

import './index.scss';

const MENU_ITEMS = [
  {
    path: '/cabinet/:cabinetId/:type',
    icon: 'home',
    supportTypes: ['gas', 'energy'],
    get label() {
      return i18n.t('Головна');
    },
  },
  {
    path: '/cabinet/:cabinetId/:type/accounts',
    icon: 'paid',
    supportTypes: ['gas'],
    get label() {
      return i18n.t('Рахунки');
    },
  },
  {
    path: '/cabinet/:cabinetId/:type/additional',
    icon: 'paid',
    supportTypes: ['gas'],
    get label() {
      return i18n.t('Додаткові угоди');
    },
  },
  {
    path: '/cabinet/:cabinetId/:type/acts',
    icon: 'downloading',
    supportTypes: ['gas', 'energy'],
    get label() {
      return i18n.t('Акт надання послуг');
    },
  },
  {
    path: '',
    icon: 'downloading',
    modal: 'service-act',
    supportTypes: ['gas', 'energy'],
    get label() {
      return i18n.t('Акт звіряння взаєморозрахунків');
    },
  },
  {
    path: '',
    icon: 'downloading',
    modal: 'agreement',
    supportTypes: ['gas'],
    get label() {
      return i18n.t('Договір про співпрацю');
    },
  },
]

@withRouter
@connect(null, { showModal })
@withTranslation()
@BEM('menu-block')
export class MenuBlock extends React.PureComponent {
  static displayName = 'MenuBlock';

  render() {
    const { match, onClick, t, bem } = this.props;

    const type = matchType(match.path);

    return (
      <ul className={bem()}>
        {MENU_ITEMS.filter(item => item.supportTypes.includes(type)).map(item => (
          <li key={item.path || item.modal} className={bem('item')}>
            <NavLink
              exact
              to={item.path.replace(':cabinetId', match.params.companyId).replace(':type', type)}
              className={bem('link')}
              onClick={(e) => {
                if (item.modal) {
                  e.preventDefault();
                  this.props.showModal(item.modal);
                }

                onClick(e);
              }}
              activeClassName={bem('link', {active: true})}
            >
              <div className={bem('icon')}>
                <Icon name={item.icon} />
              </div>
              {item.label}
            </NavLink>
          </li>
        ))}
        <li className={bem('line')} />
        <li className={bem('item')}>
          <a
            className={bem('link')}
            onClick={() => {
              onClick();
              Auth.logout();
            }}
          >
            <div className={bem('icon')}>
              <Icon name="exit_to_app" />
            </div>
            {t('Вихід')}
          </a>
        </li>
      </ul>
    );
  }
}
