import React from 'react';
import { BEM } from '@bikejs/react-bem';

import InputText from '../InputText';
import Icon from '../Icon';

import './index.scss';

const INPUT_TYPES = {
  password: 'text',
  text: 'password',
};

@BEM('input-password-component')
export default class InputPassword extends React.PureComponent {
  static displayName = 'InputPassword';

  static propTypes = InputText.propTypes;

  static defaultProps = InputText.defaultProps;

  state = {
    type: 'password',
  };

  render() {
    const { bem, ...props } = this.props;
    const { type } = this.state;

    return (
      <div className={bem()}>
        <InputText {...props} type={type} />
        <div
          className={bem('eye', { active: type === 'text' })}
          onClick={() => this.setState({ type: INPUT_TYPES[type] })}
        >
          <Icon name="remove_red_eye" />
        </div>
      </div>
    );
  }
}
