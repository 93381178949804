export class HttpMethod {
	static HEAD = 'HEAD';
	static GET = 'GET';
	static POST = 'POST';
	static PUT = 'PUT';
	static UPDATE = 'UPDATE';
	static DELETE = 'DELETE';

	static hasRequestBody(method) {
		return ![this.GET, this.HEAD].includes(method);
	}

	static hasResponseBody(method, status) {
		return ![this.HEAD].includes(method) && ![204, 500, 502].includes(status);
	}
}
