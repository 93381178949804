import Config from 'common/services/config.service';

@Config({
  env: process.env,
  clientGlobal: '__CONFIG__',
})
export default new class AppConfig {
  NODE_ENV = 'development';
  IS_DEVELOP = this.NODE_ENV === 'development';
  IS_PRODUCTION = this.NODE_ENV === 'production';
  STATIC_DEV_PORT = 4050;

  PORT = 3040;
  HOSTNAME = 'http://localhost:3040';
  API_HOST = 'https://dev.cabinet.tasenergy.com.ua';
  ROOT_PATH = '/';

  RELEASE_VERSION = process.env.RELEASE_VERSION;
  SENTRY_DSN = 'https://63a50fa967244c11a29856c64df74344@o508177.ingest.sentry.io/5600294';
}();
