import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('box-component')
export default class Box extends React.PureComponent {
  static displayName = 'Box';

  static propTypes = {

  };

  static defaultProps = {

  };

  render() {
    const { children, bem } = this.props;

    return (
      <div className={bem()}>
        {children}
      </div>
    );
  }
}
