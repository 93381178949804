export const NAMESPACE = '@modal';

const SHOW_MODAL = `${NAMESPACE}/SHOW`;
const HIDE_MODAL = `${NAMESPACE}/HIDE`;

export const selectModal = state => name => state[NAMESPACE][name];

export const showModal = (name, meta, show = true) => ({
  type: SHOW_MODAL,
  payload: { name, show, meta },
});

export const hideModal = name => ({
  type: HIDE_MODAL,
  payload: { name },
});

export const connectModal = () => (state = {}, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        [payload.name]: payload,
      };
    case HIDE_MODAL: {
      return {
        ...state,
        [payload.name]: undefined,
      };
    }
    default: {
      return state;
    }
  }
};
