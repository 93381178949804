import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import { CompanyNameEnergyBlock } from 'common/blocks/CompanyNameEnergy';
import { UserDataEnergyBlock } from 'common/blocks/UserDataEnergy';

import './index.scss';

@withTranslation()
@BEM('main-page')
export class MainEnergyPage extends React.PureComponent {
  static displayName = 'MainEnergyPage';

  render() {
    const { bem } = this.props;

    return (
      <section className={bem()}>
        <div className={bem('row')}>
          <CompanyNameEnergyBlock />
        </div>
        <div className={bem('row')}>
          <UserDataEnergyBlock />
        </div>
      </section>
    );
  }
}
