import React from 'react';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';

import Box from 'components/Box';
import Title from 'components/Title';
import Table from 'components/Table';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import Loader from 'components/Loader';

import { fetch } from 'common/services/fetch.service';
import { Cabinet } from 'common/redux/cabinet';
import { selectAgreements } from 'common/redux/cabinet/selectors';

import { AccountsFilterForm } from 'common/forms/AccountsFilter';

import './index.scss';

@fetch({
  agreements: [selectAgreements, ({ location: { query = {} } }) => Cabinet.getAgreements({ query })]
}, null, {
  loader: 'additional',
  watchQuery: true,
})
@withTranslation()
@BEM('additional-page')
export class AdditionalPage extends React.PureComponent {
  static displayName = 'AdditionalPage';

  onDownload(item) {
    const $a = document.createElement('a');

    $a.hidden = true;
    $a.href = item.url;
    $a.target = '_blank';
    $a.download = `${item.name} ${item.created}.pdf`;

    document.body.appendChild($a);

    $a.dispatchEvent(new MouseEvent('click'));

    document.body.removeChild($a);
  }

  onUpdate({month, year}) {
    const { location, history } = this.props;

    history.push({...location, query: { month, year }});
  }

  render() {
    const { agreements, t, bem } = this.props;

    return (
      <Box>
        <section className={bem()}>
          <header className={bem('header')}>
            <Title size="h3">{t('Архів додаткових угод')}</Title>

            <div className={bem('filter')}>
              <AccountsFilterForm
                onSubmit={(values) => this.onUpdate(values)}
                onReset={(values) => this.onUpdate(values)}
              />
            </div>
          </header>
          <article className={bem('main')}>
            <Table
              minWidth={600}
              theme="inset"
              columns={[
                {
                  id: 'id',
                  width: '46',
                  label: t('№')
                },
                {
                  id: 'name',
                  label: t('Найменування')
                },
                {
                  id: 'status',
                  label: t('Статус')
                },
                {
                  id: 'month',
                  label: t('Місяць')
                },
                {
                  id: 'year',
                  width: '80',
                  label: t('Рік')
                },
                {
                  id: 'created',
                  width: '120',
                  label: t('Створено')
                },
                {
                  id: 'options',
                  align: 'right',
                  label: t('Опції')
                },
              ]}
              rows={agreements.map((item, index) => ({
                id: item.id,
                name: item.name,
                status: item.status,
                month: item.month,
                year: item.year,
                created: item.created,
                options: (
                  <div className={bem('options')}>
                    <DropDown
                      arrow={false}
                      nowrap
                      align="right"
                      valign={(index === 2 || agreements.length === 1) ? 'top' : 'bottom'}
                      controlRender={() => (
                        <Icon name="menu" color="#234CAC" />
                      )}
                      onChange={() => {
                        this.onDownload(item);
                      }}
                      options={[
                        {
                          label: (
                            <span className={bem('options-label')}>
                              <span className={bem('options-label-icon')}>
                                <Icon name="file_download" color="#234CAC" />
                              </span>
                            {t('Завантажити файл')}
                            </span>
                          ),
                          value: 'download',
                        }
                      ]}
                    />
                  </div>
                ),
              }))}
              placeholder={t('Немає угод')}
            />
          </article>
        </section>
        <Loader name="additional" color="white" />
      </Box>
    );
  }
}
