import React from 'react';
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';

import InputText from 'components/InputText';
import InputPassword from 'components/InputPassword';
import InputCheckbox from 'components/InputCheckbox';
import Button from 'components/Button';
import { H2 } from 'components/Title';

import { validate, required, email } from 'common/helpers/validators.helper';
import { useBEM } from '@bikejs/react-bem';

import './index.scss';

export const LoginForm = ({ onSubmit }) => {
  const bem = useBEM('login-form');
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className={bem()}>
          <header className={bem('title')}>
            <H2>{t('Авторизація')}</H2>
          </header>
          <div className={bem('row')}>
            <Field
              autoFocus
              name="username"
              component={InputText}
              label={t('Введіть e-mail')}
              validate={validate(
                required(),
                email(),
              )}
            />
          </div>
          <div className={bem('row')}>
            <Field
              name="password"
              component={InputPassword}
              label={t('Пароль')}
              validate={validate(
                required(),
              )}
            />
          </div>
          <div className={bem('row', { box: true })}>
            <div>
              <Field
                name="remember"
                component={InputCheckbox}
                type="checkbox"
                label={t('Запам\'ятати мене')}
              />
            </div>
            &nbsp;
            <div>
              <Button theme="link" to="/recovery" lower>
                {t('Забули пароль?')}
              </Button>
            </div>
          </div>

          <footer className={bem('footer')}>
            <Button type="submit" disabled={submitting} block>
              {t('Вхід')}
            </Button>

            <div className={bem('registration')}>
              {t('Немає аккаунта?')}&nbsp;
              <Button theme="link" to="/registration" lower>
                {t('Зареєструватися')}
              </Button>
            </div>
          </footer>
        </form>
      )}
    />
  );
};
