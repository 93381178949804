import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { BEM } from '@bikejs/react-bem';
import { renderRoutes } from 'react-router-config';
import { connect } from 'react-redux';

import Modal from 'components/Modal';

import { fetch } from 'common/services/fetch.service';
import { EnergyService } from 'common/redux/energy';
import { selectHeaderInfo } from 'common/redux/energy/selectors';

import {AsideBlock} from 'common/blocks/Aside';
import {HeaderBlock} from 'common/blocks/Header';
import {ServiceActForm} from 'common/forms/ServiceAct';
import {AgreementBlock} from 'common/blocks/Agreement';

import './index.scss';

@fetch({
  info: [selectHeaderInfo, () => EnergyService.getInitialData()]
}, null, {loader: 'global'})
@withTranslation()
@BEM('cabinet-energy-layout')
export class CabinetEnergyLayout extends React.PureComponent {
  static displayName = 'CabinetEnergyLayout';

  render() {
    const { info, route, bem, loading } = this.props;

    return (
      <React.Fragment>
        <Helmet defaultTitle="Кабінет - TAS Energy">
          <meta name="description" content="Кабінет - TAS Energy" />
        </Helmet>
        {!loading && (
          <section className={bem()}>
            <aside className={bem('aside')}>
              <AsideBlock />
            </aside>
            <article className={bem('main')}>
              <header className={bem('header')}>
                <HeaderBlock user={info.user} bill={info.bill} />
              </header>
              <article className={bem('content')}>
                {renderRoutes(route.routes)}
              </article>
            </article>
          </section>
        )}
        <Modal name="service-act">
          <ServiceActForm type="energy" />
        </Modal>
        <Modal name="agreement">
          <AgreementBlock />
        </Modal>
      </React.Fragment>
    );
  }
}
