import React from 'react';
import { Form, Field } from 'react-final-form'
import { withTranslation } from 'react-i18next';

import { BEM } from '@bikejs/react-bem';

import InputText from 'components/InputText';
import InputPassword from 'components/InputPassword';
import Button from 'components/Button';

import { sessionStorage } from 'common/services/storage.service';
import { withLoader } from 'components/Loader/redux';
import { Auth } from 'common/redux/auth';

import {
  required,
  validate,
  passwordMatch,
  password,
  email,
} from 'common/helpers/validators.helper';

import './index.scss';

@withTranslation()
@BEM('recovery-form')
export class RecoveryForm extends React.PureComponent {
  static displayName = 'RecoveryForm';

  async onSubmit(values) {
    const data = await withLoader('auth', Auth.recoveryPassword, Auth)({
      body: values,
    });

    if (data.next) {
      this.props.history.push(data.next.path);
    }

    return data;
  }

  render() {
    const { bem, t } = this.props;

    return (
      <Form
        onSubmit={values => this.onSubmit(values)}
        initialValues={sessionStorage.get('recovery.first')}
        validate={validate(
          passwordMatch({
            password: 'password',
            repeat: 'passwordConfirm'
          })
        )}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} className={bem()}>
            <div className={bem('row')}>
              <Field
                autoFocus
                name="email"
                component={InputText}
                label={t('Введіть e-mail')}
                validate={validate(
                  required(),
                  email(),
                )}
              />
            </div>
            <div className={bem('row', { help: true })}>
              <Field
                name="password"
                component={InputPassword}
                label={t('Вигадайте пароль')}
                help={(
                  <div className={bem('help')}>
                    {t('Пароль має містити хоча б одну велику літеру та цифру. Довжина паролю не менше 8 символів.')}
                    <b>{t('Приклад: Pruklad00')}</b>
                  </div>
                )}
                validate={validate(
                  required(),
                  password(),
                )}
              />
            </div>
            <div className={bem('row')}>
              <Field
                name="passwordConfirm"
                component={InputPassword}
                label={t('Підтвердити пароль')}
                validate={validate(
                  required(),
                )}
              />
            </div>
            <footer className={bem('footer')}>
              <Button type="submit" disabled={submitting} block>
                {t('Продовжити')}
              </Button>
            </footer>
          </form>
        )}
      />
    );
  }
}
