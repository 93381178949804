import React from 'react';
import PropTypes from 'prop-types';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('input-checkbox')
export default class InputCheckbox extends React.PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      onChange: PropTypes.func,
    }),
    render: PropTypes.func,
    help: PropTypes.any,
  };

  static defaultProps = {
    meta: {},
    input: {
      type: 'checkbox'
    }
  };

  render() {
    const { input, meta, children, render, label, help, disabled, bem, ...other } = this.props;
    const error = (meta.touched || meta.submitFailed) && meta.error;

    return (
      <label className={bem({ error: Boolean(error), disabled })}>
        <input
          className={bem('input')}
          autoComplete="off"
          disabled={disabled}
          {...input}
          {...other}
        />
        <span className={bem('label')}>{label}</span>
      </label>
    )
  }
}
