import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { BEM } from '@bikejs/react-bem';

import './index.scss';

@BEM('button')
export default class Button extends React.PureComponent {
  static displayName = 'Button';

  static propTypes = {
    children: PropTypes.any,
    to: PropTypes.string,
    color: PropTypes.oneOf(['orange', 'blue']),
    size: PropTypes.oneOf(['small', 'middle']),
    theme: PropTypes.oneOf(['default', 'plain', 'link', 'border', 'nav']),
    type: PropTypes.oneOf(['button', 'submit']),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    block: PropTypes.bool,
    exact: PropTypes.bool,
    target: PropTypes.oneOf(['_blank']),
  };

  static defaultProps = {
    color: 'orange',
    size: 'middle',
    theme: 'default',
    type: 'button',
    disabled: false,
    block: false,
    exact: false,
    target: undefined,
  };

  render() {
    const { children, to, color, size, theme, block, type, disabled, onClick, lower, target, exact, bem } = this.props;
    const className = bem({ theme, color, size, disabled, lower, block });

    if (to === undefined) {
      return (
        <button onClick={onClick} type={type} className={className}>
          {children}
        </button>
      );
    }

    if (to.startsWith('http') || target) {
      return (
        <a href={to} target={target} onClick={onClick} className={className}>
          {children}
        </a>
      );
    }

    return (
      <NavLink exact={exact} activeClassName={bem({ active: true })} to={to} onClick={onClick} className={className}>
        {children}
      </NavLink>
    );
  }
}
