const SHOW_NOTIFICATION = '@notifications/ADD';
const HIDE_NOTIFICATION = '@notifications/REMOVE';

const store = { dispatch: () => { }, getState: () => ({ }) };

export const showNotification = ({ type, title, message }) => ({
  type: SHOW_NOTIFICATION,
  payload: {
    type,
    title,
    message,
    key: Math.random().toString(32),
  },
});

export const hideNotification = ({ key }) => ({
  type: HIDE_NOTIFICATION,
  payload: { key },
});

export const notificationsMiddleware = ({ dispatch, getState }) => {
  store.dispatch = dispatch;
  store.getState = getState;

  return next => action => next(action);
};

export const connectNotifications = () => (state = [], action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return [
        ...state,
        action.payload,
      ];
    case HIDE_NOTIFICATION:
      const index = state.findIndex(item => item.key === action.payload.key);

      state.splice(index, 1);

      return [
        ...state,
      ];
    default:
      return state;
  }
};
